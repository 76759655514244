<template>
  <div class="login-bg fill-height">
    <v-container class="fill-height pb-15 justify-center text-center">
      <v-card width="360">
        <v-card-title>Mein Standort - Backend</v-card-title>
        <v-card-text>
          <v-text-field v-model="email" label="E-Mail" type="email"/>
          <v-text-field v-model="password" label="Passwort" type="password" @keyup.enter="isEmailValid ? signIn() : null"/>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text :loading="loading" @click="signIn">Anmelden</v-btn>
          <v-spacer/>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { signInWithEmailAndPassword } from 'firebase/auth'

export default {
  name: 'SignIn',
  data () {
    return {
      email: null,
      password: null,
      loading: false
    }
  },
  computed: {
    isEmailValid () {
      return /\S+@\S+\.\S+/.test(this.email)
    }
  },
  methods: {
    async signIn () {
      this.loading = true
      await signInWithEmailAndPassword(this.$fb.auth, this.email, this.password)
        // .then(userCredential => {
        //   console.log(userCredential)
        // })
        .catch(err => {
          console.error('signInWithEmailAndPassword error', err)

          let text = 'Anmeldung fehlgeschlagen'
          if (err.code === 'auth/user-not-found') {
            text += ': E-Mail unbekannt'
          }
          if (err.code === 'auth/wrong-password') {
            text += ': Falsches Passwort'
          }
          this.$root.snack = {
            text,
            color: 'error'
          }
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>
.login-bg {
  background-image: url('~@/assets/login_bg.jpg');
  background-size: cover;
  background-position: center;
}
</style>