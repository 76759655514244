<template>
  <v-app v-if="eventId && uid">
    <v-main>
      <div>Renderer started..</div>
      <div>Event: {{ uid }}</div>
      <div>Authenticated: {{ $root.userId }}</div>
      <div>Import: {{ imported !== null ? (imported ? 'OK' : 'ERROR') : '' }}</div>
      <div>Publish: {{ published !== null ? (published ? 'OK' : 'ERROR') : '' }}</div>
      <div v-if="done" class="renderer-done">Done.</div>
      <div v-else-if="error" class="renderer-done">ERROR: {{ error }}</div>
      <event-renderer v-if="published" :public-data="publicData" @done="done = true"/>
    </v-main>
  </v-app>
</template>

<script>
import { signInWithCustomToken } from 'firebase/auth'
import EventRenderer from '@/components/EventRenderer'

export default {
  name: 'App',
  components: { EventRenderer },
  data () {
    return {
      imported: null,
      published: null,
      publicData: null,
      done: null,
      error: null
    }
  },
  computed: {
    eventId () {
      return this.$route.query.id
    },
    uid () {
      return this.$route.query.uid
    }
  },
  methods: {
    async authenticate () {
      const getSignInTkn = this.$fb.getFn('getSignInTkn')
      const response = await getSignInTkn({ id: this.eventId, uid: this.uid })
      await signInWithCustomToken(this.$fb.auth, response.data?.tkn)
    },
    async importData () {
      const importDataFunc = this.$fb.getFn('importData')
      const response = await importDataFunc({ id: this.eventId })
      this.imported = response.data?.ok || false
      if (this.imported) {
        this.publish()
      } else {
        this.error = 'Import failed'
      }
    },
    async publish () {
      const publishFunc = this.$fb.getFn('publish')
      const response = await publishFunc({ id: this.eventId })
      this.publicData = response.data?.publicData
      this.published = response.data?.ok || false
      if (!this.published) {
        this.error = 'Publish failed'
      }
    }
  },
  watch: {
    '$root.userId' (userId) {
      if (userId) {
        this.importData()
      }
    }
  },
  async mounted () {
    await this.authenticate()
  }
}
</script>
