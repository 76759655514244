<template>
  <v-dialog
    :value="true"
    scrollable
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>{{ done ? 'Resultat' : 'Wird gerendert..' }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark :loading="!done" @click="render">
          <v-icon>mdi-reload</v-icon>
        </v-btn>
        <v-btn icon dark :loading="!done" @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text v-if="halls && halls.length > 0" class="pa-5">
        <div v-for="hall in halls" :key="hall.id" class="mb-5">
          <div class="d-flex align-center">
            <div>Halle {{ hall.id }}</div>
            <v-btn v-if="preview" icon @click="downloadBlob(hall.id)">
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </div>
          <canvas
            :ref="'canvas_' + hall.key"
            :width="hall.w * getScale(hall.id)"
            :height="hall.h * getScale(hall.id)"
            :style="'width: 100%; aspect-ratio: ' + hall.w / hall.h"
          />
        </div>
      </v-card-text>
      <v-card-text v-else class="pa-5">
        Daten unvollständig (keine Zeichungsdaten vorhanden) - Bitte zuerst importieren.
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import FileMixin from '@/mixins/FileMixin'
import downloadjs from 'downloadjs'

const CANVAS_MAX = 16384 // max allowed canvas dimension (w/h)
const SIZE_MAX = CANVAS_MAX / 2 // CANVAS_MAX leads to 40mb images!
const IMG_SIZE = 3000 // rendered size (w/h, containing)

const FONTSIZES = {
  S: 10,
  M: 13, // Default
  L: 16
}

export default {
  name: 'EventRenderer',
  mixins: [FileMixin],
  props: {
    publicData: Object,
    preview: Boolean,
    hideBg: Boolean,
    themeColors: Boolean
  },
  data () {
    return {
      debug: false,
      scale: 0,
      renderedCount: 0,
      blobs: []
    }
  },
  computed: {
    halls () {
      return this.publicData?.halls
    },
    done () {
      return this.renderedCount === this.halls.length
    }
  },
  methods: {
    getScale (hallId) {
      const hall = this.halls.find(h => h.id === hallId)
      const imgScale = Math.min(IMG_SIZE / hall.w, IMG_SIZE / hall.h)
      const maxScale = Math.min(SIZE_MAX / hall.w, SIZE_MAX / hall.h)
      return Math.min(imgScale, maxScale)
    },
    async render () {
      this.renderedCount = 0
      this.blobs = []

      for (let i = 0; i < this.halls.length; i++) {
        const hall = this.halls[i]

        this.scale = this.getScale(hall.id)
        const scaleFont = FONTSIZES[this.publicData.fontsize || 'M'] - Math.floor(18 / this.scale)
        const defaultFont = scaleFont + 'px MNKYMarcel'

        const canvas = this.$refs['canvas_' + hall.key][0]
        const ctx = canvas.getContext('2d')
        ctx.globalCompositeOperation = 'multiply'
        ctx.font = defaultFont
        ctx.textAlign = 'center'
        ctx.textBaseline = 'middle'

        ctx.clearRect(0, 0, canvas.width, canvas.height)
        ctx.scale(this.scale, this.scale)
        
        // bg img
        if (!this.hideBg) {
          await this.renderImg(ctx, hall)
        }
        
        // rotation
        // if (hall.rot) {
        //   ctx.translate(317, -67) // 317,-67 is the distance in meters from the orignal nullpoint (XML) vs the new once (top-left of img)
        //   ctx.rotate(hall.rot * Math.PI / 180)
        // }

        // offset
        ctx.translate(hall.x, hall.y)

        if (!this.publicData.no_draw) {
          // booths
          this.publicData.booths
            .filter(b => b.hallId === hall.id && b.path)
            .forEach(b => {
              if (!b.hide_drawing) {
                let themeColor = null
                if (this.themeColors) {
                  const exhibitor = this.publicData.exhibitors.find(e => e.id === b.id)
                  themeColor = this.publicData.themes?.find(t => exhibitor?.themeIds?.includes(t.id))?.color
                }
                ctx.fillStyle = b.color || themeColor || this.publicData.plan_booth || "#ccc"
                ctx.strokeStyle = b.color_text || this.publicData.plan_booth_text || "#ccc"
                this.renderPath(ctx, b.path)
              }
              if (!b.hide_text) {
                const textColor = b.color_text || this.publicData.plan_booth_text
                this.renderTextInPath(ctx, b.no, b.path, textColor, hall.rot ? b.x : null, hall.rot ? b.y : null)
              }
            })

          // texts
          if (this.publicData.texts) {
            this.publicData.texts
              .filter(t => t.hallId === hall.id && t.path)
              .forEach(t => {
                ctx.font = (t.bold ? 'bold ' : '') + (t.size || scaleFont) + 'px MNKYMarcel'
                const textColor = t.color || this.publicData.plan_booth_text
                this.renderTextInPath(ctx, t.text, t.path, textColor, hall.rot ? t.x : null, hall.rot ? t.y : null)
              })
            ctx.font = defaultFont
          }

          // DEBUG offset marker
          if (this.debug) {
            ctx.save()
            ctx.strokeStyle = 'green'
            ctx.lineWidth  = 0.5
            ctx.beginPath();
            ctx.arc(0, 0, 3, 0, 2 * Math.PI)
            ctx.stroke()
            ctx.restore()
          }
        }

        if (this.preview) {
          this.saveBlob(hall, canvas)
        } else {
          this.saveMap(hall, canvas)
        }
      }
    },
    async renderPath (ctx, path) {
      let pathParts = null
      if (typeof path === 'string') {
        pathParts = path.split(' ').map(p => parseFloat(p))
      } else {
        pathParts = [...path]
      }
      ctx.beginPath()
      ctx.moveTo(pathParts.shift(), pathParts.shift())
      while (pathParts.length > 0) {
        ctx.lineTo(pathParts.shift(), pathParts.shift())
      }
      ctx.closePath()
      ctx.fill()
      ctx.lineWidth = 0.05
      ctx.stroke()
    },
    async renderText (ctx, text, x, y, w, fill) {
      ctx.save()
      ctx.scale(1 / this.scale, 1 / this.scale)
      ctx.globalCompositeOperation = 'source-over'
      ctx.fillStyle = fill || '#010101'
      ctx.fillText(text, x * this.scale, y * this.scale, w ? w * this.scale : undefined)
      ctx.restore()
    },
    async renderTextInPath (ctx, text, path, fill, pinX = null, pinY = null) {
      let pathParts = null
      if (typeof path === 'string') {
        pathParts = path.split(' ').map(p => parseFloat(p))
      } else {
        pathParts = [...path]
      }
      if (pinX && pinY) {
        const x = parseFloat(pinX) - 5
        const y = parseFloat(pinY) + 1
        this.renderText(ctx, text, x + 0.1, y + 0.1, fill)
      }
      else if (pathParts.length >= 6) {
        let x = parseFloat(pathParts[0])
        let y = parseFloat(pathParts[1])
        let w = parseFloat(pathParts[2]) - x
        let h = parseFloat(pathParts[5]) - y
        if (w <= 0 || h <= 0) {
          // try other aproach, find top-left corner and use this
          for (let i = 0; i < pathParts.length / 2; i++) {
            const px = parseFloat(pathParts[i*2])
            const py = parseFloat(pathParts[i*2+1])
            x = Math.min(x, px)
            y = Math.min(y, py)
          }
          w = 4
          h = 4
        }
        this.renderText(ctx, text, x + w / 2, y + h / 2, w, fill)
      } else if (pathParts.length >= 2) {
        const x = parseFloat(pathParts[0])
        const y = parseFloat(pathParts[1])
        this.renderText(ctx, text, x + 0.1, y + 0.1, fill)
      }
    },
    async renderImg (ctx, hall) {
      return new Promise(resolve => {
        if (hall.img?.src) {
          const img = new Image()
          img.setAttribute('crossorigin', 'anonymous')
          img.onload = function() {
            ctx.drawImage(img, 0, 0, hall.w, hall.h)
            resolve()
          }
          img.src = this.getFileURL(hall.img.src)
        } else {
          resolve() // noop
        }
      })
    },
    async saveMap (hall, canvas) { // saves (uploads) rendered image to server
      canvas.toBlob(async blob => {
        const fileName = this.publicData.slug + '_' + hall.key + '_' + this.publicData.ms + '.jpg'
        const file = new File([blob], fileName, { type: 'image/jpg' })
        if (!this.debug) {
          await this.uploadFile(file, 'maps', false)
        }
        console.log('done: ' + hall.id + ': ' + fileName)
        this.renderedCount++
      }, 'image/jpg', 0.5)
    },
    async saveBlob (hall, canvas) { // stores canvas as blob locally
      canvas.toBlob(async blob => {
        const modes = []
        if (this.hideBg) modes.push('nobg')
        if (this.themeColors) modes.push('themes')
        const fileName = this.publicData.slug + '_' + hall.key + (modes.length > 0 ? ('_' + modes.join('_')) : '') + '_' + this.publicData.ms + '.jpg'
        this.blobs.push({
          fileName,
          blob,
          hall
        })
        console.log('done: ' + hall.id + ': ' + fileName)
        this.renderedCount++
      }, 'image/jpg', 1)
    },
    downloadBlob (hallId) {
      const blb = this.blobs.find(b => b.hall.id === hallId)
      downloadjs(blb.blob, blb.fileName, 'image/jpg')
    }
  },
  watch: {
    done (done) {
      if (done) {
        this.$emit('done')
      }
    }
  },
  async mounted () {
    if (this.halls) {
      // const fontFace = new FontFace('Montserrat', 'url(https://fonts.gstatic.com/s/montserrat/v21/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2)');
      const fontFace = new FontFace('MNKYMarcel', 'url(/fonts/MNKYMarcel-Regular.woff2)');
      const font = await fontFace.load()
      document.fonts.add(font)
      this.$nextTick(() => {
        this.debug = this.$route.query.debug || false
        this.render()
      })
    }
  }
}
</script>

<style scoped>
canvas {
  border: 1px solid black;
}
</style>