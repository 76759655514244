<template>
  <v-app>
    <v-navigation-drawer
      v-if="$root.userId"
      v-model="drawer"
      app
      clipped
    >
      <div>
        <v-list dense nav>
          <!-- <v-list-item to="/">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <v-list-item to="/events">
            <v-list-item-icon>
              <v-icon>mdi-calendar-star</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Events</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-divider/> -->
          <v-list-item v-if="$root.user?.admin || $root.user?.permSettings" to="/settings">
            <v-list-item-icon>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Einstellungen</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <template v-if="$root.user?.admin">
            <v-divider/>
            <v-subheader>Administration</v-subheader>
            <v-list-item to="/users">
              <v-list-item-icon>
                <v-icon>mdi-account-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Benutzer</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/locations">
              <v-list-item-icon>
                <v-icon>mdi-map-marker-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Standorte</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </div>
      <template v-slot:append>
        <v-divider></v-divider>
        <v-list dense nav>
          <v-subheader>{{ $root.user?.email }}</v-subheader>
          <v-list-item @click="$root.signOut">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Abmelden</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-navigation-drawer
      v-model="drawerRight"
      app
      right
      clipped
      disable-resize-watcher
      width="360"
    >
      <iframe
        v-if="drawerRight"
        src="https://docs.google.com/document/d/e/2PACX-1vTNK2Fcz2QlEI4YszZRKHm9XZ_-e3g56UOXHuPDZ0539i1pgzDAcQzFzX5xcnhYWU1Z2kFZbvOCZTpY/pub?embedded=true"
        style="display: block; width: 100%; height: 100%; overflow-y: auto;"
        frameborder="0"
      />
    </v-navigation-drawer>

    <v-app-bar
      v-if="$root.userId"
      app
      color="primary"
      dark
      clipped-left
      clipped-right
    >
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title v-if="!live" class="mr-8 "># TESTUMGEBUNG #</v-toolbar-title>
      <v-toolbar-title>{{ $route.meta?.title || $route.name }}</v-toolbar-title>
      <v-spacer/>
      <v-select
        v-if="$root.locations?.length > 1"
        v-model="$root.locationId"
        :items="$root.locations?.map(l => ({ text: l.name, value: l.id, }))"
        placeholder="Standort"
        hide-details
        style="max-width: 256px"
      />
      <v-btn icon @click.stop="drawerRight = !drawerRight" class="ml-5">
        <v-icon>mdi-help-circle-outline</v-icon>
      </v-btn>
    </v-app-bar>

    <v-snackbar v-model="showSnack" top :color="snack ? snack.color : null">
      {{ snack ? snack.text : null }}
    </v-snackbar>

    <v-main>
      <router-view v-if="$root.userId && $root.locations"/>
      <sign-in v-else-if="$root.userId === false"/>
    </v-main>
  </v-app>
</template>

<script>
import { LIVE } from '@/plugins/firebase'
import SignIn from '@/views/SignIn'

export default {
  name: 'App',
  components: { SignIn },
  data () {
    return {
      drawer: false,
      drawerRight: false,
      showSnack: false
    }
  },
  computed: {
    live () {
      return LIVE
    },
    snack () {
      return this.$root.snack
    }
  },
  watch: {
    snack (snack) {
      if (snack) {
        this.showSnack = true
      }
    },
    showSnack (showSnack) {
      if (!showSnack) {
        this.$root.snack = null
      }
    }
  }
}
</script>
