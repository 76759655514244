import Vue from 'vue'
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/de'

dayjs.extend(updateLocale)
dayjs.extend(weekOfYear)
dayjs.extend(advancedFormat)
dayjs.extend(relativeTime)

dayjs.locale('de')
dayjs.updateLocale('de', {
  weekStart: 1, // week starts with monday
  yearStart: 4 // first week of year is the week that contains the 4th jan (In Europe, the first calendar week of the year is the week that contains four days of the new year. Source: https://www.calendar-week.org/)
})

const DATE_FORMAT_ISO = 'YYYY-MM-DD'
const DATE_FORMAT = 'DD.MM.YYYY'
const TIME_FORMAT = 'HH:mm'
const KW_FORMAT = 'YYYY:ww'

dayjs.prototype.formatDateISO = function () {
  return this.format(DATE_FORMAT_ISO)
}

dayjs.prototype.formatDate = function () {
  return this.format(DATE_FORMAT)
}

dayjs.prototype.formatTime = function () {
  return this.format(TIME_FORMAT)
}

dayjs.prototype.formatDateTime = function () {
  return this.format(DATE_FORMAT + ' ' + TIME_FORMAT)
}

dayjs.prototype.formatKw = function () {
  return this.format(KW_FORMAT)
}

Vue.prototype.$dayjs = dayjs

// filters
Vue.filter('date', function (value) {
  if (!value || (value.toDate === undefined && !/^\d{4}-\d{2}-\d{2}$/.test(value))) return ''
  const date = value.toDate ? value.toDate() : value
  return dayjs(date).formatDate()
})

Vue.filter('time', function (value) {
  if (!value || value.toDate === undefined) return ''
  return dayjs(value.toDate()).formatTime()
})

Vue.filter('datetime', function (value) {
  if (!value || value.toDate === undefined) return ''
  const dj = dayjs(value.toDate())
  return dj.isSame(dayjs(), 'day') ? dj.formatTime() : dj.formatDateTime()
})

Vue.filter('datetimeago', function (value) {
  if (!value || value.toDate === undefined) return ''
  return dayjs(value.toDate()).from()
})

Vue.filter('daterange', function (object) {
  if (!object || !object.begin || object.begin.toDate === undefined || !object.end || object.end.toDate === undefined) return ''

  const begin = dayjs(object.begin.toDate())
  const end = dayjs(object.end.toDate())

  if (begin.isSame(end, 'day')) {
    return begin.format(DATE_FORMAT + ', ' + TIME_FORMAT) + ' - ' + end.format(TIME_FORMAT)
  } else {
    return begin.format(DATE_FORMAT + ' ' + TIME_FORMAT) + ' - ' + end.format(DATE_FORMAT + ' ' + TIME_FORMAT)
  }
})

Vue.filter('birthday', function (value) {
  if (!value || value.toDate === undefined) return ''
  return dayjs(value.toDate()).formatDate()
})
