import Vue from 'vue'
import App from './App.vue'
import Renderer from './Renderer.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins/day'
import './plugins/firebase'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import { collection, query, onSnapshot } from 'firebase/firestore'
import { APP_URL } from '@/plugins/firebase'
import './assets/styles.css'

Vue.config.productionTip = false
Vue.config.ignoredElements = ['map-widget']

new Vue({
  router,
  vuetify,
  data () {
    return {
      user: null,
      snack: null,
      locations: null,
      locationId: null
    }
  },
  computed: {
    userId () {
      return this.user ? this.user.uid : this.user
    },
    location () {
      return this.locations?.find(l => l.id === this.locationId)
    }
  },
  methods: {
    signOut () {
      this.user = null
      this.$nextTick(() => {
        signOut(this.$fb.auth)
      })
    }
  },
  watch: {
    async user (user) {
      if (user) {
        const q = query(collection(this.$fb.db, 'locations'))
        this.unsubscribe = onSnapshot(q, (snap) => {
          this.locations = snap.docs
            .map(doc => ({ id: doc.id, ...doc.data() }))
            .filter(l => !l._deleted)
            .filter(l => user.admin || user.locations.includes(l.id))
            .sort((a, b) => a.name < b.name ? -1 : 1)
          if (this.locations.length > 0) {
            if (user.admin) {
              this.locationId = this.locations[0].id
            } else {
              for (let i = 0; i < user.locations.length; i++) {
                if (this.locations.find(l => l.id === user.locations[i])) {
                  this.locationId = user.locations[i]
                  break
                }
              }
            }
          }
        })
      } else {
        if (this.unsubscribe) {
          this.unsubscribe()
        }
        this.locations = null
      }
    },
    location (location, _location) {
      const rootStyle = document.documentElement.style // const rootStyle = document.querySelector(':root').style
      rootStyle.setProperty('--v-primary-base', location?.color || '#cc0000 ')
      if (location && _location && this.$route.name === 'Event') {
        this.$router.push('/events')
      }
    }
  },
  created () {
    onAuthStateChanged(this.$fb.auth, async (user) => {
      if (user) {
        const userAuth = { uid: user.uid, email: user.email }
        const idTokenResult = await user.getIdTokenResult(true)
        if (idTokenResult.claims.admin) {
          userAuth.admin = true
        }
        const userData = await this.$fb.readDoc('users/' + user.uid)
        this.user = Object.assign({}, userAuth, userData)
      } else {
        this.user = false
      }
    })
  },
  mounted () {
    if (window.location.pathname !== '/renderer') {
      // load map-widget script for preview
      const WIDGET_SRC = 'map-widget/map-widget.js'
      const script = document.createElement('script')
      script.src = APP_URL + WIDGET_SRC
      window.document.head.append(script)
    }
  },
  render: h => h(window.location.pathname !== '/renderer' ? App : Renderer)
}).$mount('#app')
