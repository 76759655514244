import Vue from 'vue'

import { initializeApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getFirestore, connectFirestoreEmulator, doc, getDoc, collection, getDocs, query, orderBy, limit } from 'firebase/firestore'
import { getFunctions, connectFunctionsEmulator, httpsCallable } from 'firebase/functions'
import { getStorage, connectStorageEmulator } from 'firebase/storage'


const USE_EMULATOR_SUITE = location.hostname === 'localhost'
const LIVE = !USE_EMULATOR_SUITE && location.hostname.indexOf('test') === -1

const FUNCTIONS_REGION = 'europe-west6'

const firebaseConfig = LIVE ? {
  apiKey: "AIzaSyA2QJlVNscGb4IomECBNyRKSryvyqgQrjk",
  authDomain: "mein-standort-2021.firebaseapp.com",
  projectId: "mein-standort-2021",
  storageBucket: "mein-standort-2021.appspot.com",
  messagingSenderId: "410506922399",
  appId: "1:410506922399:web:c65587fc55eca5e4c695aa",
  measurementId: "G-S7Z2LLLP56"
} : {
  apiKey: "AIzaSyCyuQ44rOcriImM3AHa7rNw5vl2ID1nIaE",
  authDomain: "mein-standort-test.firebaseapp.com",
  projectId: "mein-standort-test",
  storageBucket: "mein-standort-test.appspot.com",
  messagingSenderId: "637362232013",
  appId: "1:637362232013:web:23c311a8e3def486c72784"
}

const PROJECT_ID = firebaseConfig.projectId

const APP_URL = USE_EMULATOR_SUITE
  ? 'http://localhost:8080/'
  : LIVE
    ? 'https://app.mein-standort.ch/'
    : 'https://mein-standort-test.web.app/'


const app = initializeApp(firebaseConfig)


const auth = getAuth(app)
if (USE_EMULATOR_SUITE) {
  connectAuthEmulator(auth, 'http://127.0.0.1:9099', { disableWarnings: true })
}


const db = getFirestore(app)
if (USE_EMULATOR_SUITE) {
  connectFirestoreEmulator(db, 'localhost', 5002)
}

const readDoc = async (path) => {
  const snap = await getDoc(doc(db, path))
  return { id: snap.id, ...snap.data() }
}

const readDocs = async (path) => {
  const snap = await getDocs(query(collection(db, path)))
  return !snap.empty ? snap.docs.map(doc => ({ id: doc.id, ...doc.data() })) : null
}

const readNewestDoc = async (path) => {
  const snap = await getDocs(query(collection(db, path), orderBy('ts', 'desc'), limit(1)))
  return !snap.empty ? { id: snap.docs[0].id, ...snap.docs[0].data() } : null
}

const fn = getFunctions(app, FUNCTIONS_REGION)
if (USE_EMULATOR_SUITE) {
  connectFunctionsEmulator(fn, 'localhost', 5001)
}

const getFn = (name) => {
  return httpsCallable(fn, name)
}


const fs = getStorage(app)
if (USE_EMULATOR_SUITE) {
  connectStorageEmulator(fs, 'localhost', 9199)
}


const $fb = {
  app,
  auth,
  db,
  fn,
  fs,
  readDoc,
  readDocs,
  readNewestDoc,
  getFn
}

Vue.prototype.$fb = $fb

export default $fb

export {
  USE_EMULATOR_SUITE,
  LIVE,
  PROJECT_ID,
  APP_URL
}
