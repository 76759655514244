import { USE_EMULATOR_SUITE, PROJECT_ID } from '@/plugins/firebase'
import { ref, uploadBytes } from 'firebase/storage'
import SparkMD5 from 'spark-md5'
require('./Blob') // for shitty Safari (Blob.text)

const STORAGE_URL = USE_EMULATOR_SUITE
   ? 'http://localhost:9199/v0/b/' + PROJECT_ID + '.appspot.com/o/'
   : 'https://firebasestorage.googleapis.com/v0/b/' + PROJECT_ID + '.appspot.com/o/'

export default {
  methods: {
    async uploadFile (file, path = 'contents', hash = true) {
      let fileName = null
      if (hash) {
        const fileText = await file.text()
        const fileHash = SparkMD5.hash(fileText)
        const fileType = file.name.substr(file.name.lastIndexOf('.') + 1).toLowerCase()
        fileName = fileHash + '.' + fileType
      } else {
        fileName = file.name
      }
      const filePath = path + '/' + fileName
      await uploadBytes(ref(this.$fb.fs, filePath), file, { cacheControl: 'public,max-age=31536000' })
      return fileName
    },
    getFileURL (fileName, path = 'contents') {
      if (!fileName) return ''
      const filePath = path + '/' + fileName
      return STORAGE_URL + filePath.replace(/\//g, '%2F') + '?alt=media'
    },

    // https://javascript.plainenglish.io/resize-images-on-the-client-using-javascript-before-uploading-them-17a6484e3a00
    async resizeImage (
      url,
      options = {
        maxWidth: 1024,
        maxHeight: 1024,
        contentType: 'image/png',
        quality: 0.75
      }
    ) {
      const calculateSize = (img) => {
        let w = img.width,
          h = img.height;
        if (w > h) {
          if (w > options.maxWidth) {
            h = Math.round((h * options.maxWidth) / w);
            w = options.maxWidth;
          }
        } else {
          if (h > options.maxHeight) {
            w = Math.round((w * options.maxHeight) / h);
            h = options.maxHeight;
          }
        }
        return [w, h];
      };

      return new Promise((resolve) => {
        const img = new Image();
        img.src = url;
        img.onerror = function () {
          URL.revokeObjectURL(this.src);
        };
        img.onload = function () {
          URL.revokeObjectURL(this.src);
          const [newWidth, newHeight] = calculateSize(
            img,
            options.maxWidth,
            options.maxHeight
          );
          const canvas = document.createElement("canvas");
          canvas.width = newWidth;
          canvas.height = newHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, newWidth, newHeight);

          canvas.toBlob(
            (blob) => {
              resolve(blob);
            },
            options.contentType,
            options.quality
          );
        };
      });
    }
  }
}
